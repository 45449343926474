import React from 'react';
import GoogleMapReact from 'google-map-react';
import { Paper, Typography, useMediaQuery } from '@material-ui/core';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import Rating from '@material-ui/lab/Rating';

import useStyles from './styles'

import mapStyles from './mapStyles'
 
const Map = ({ setCoordinates, setBounds, coordinates, places, setChildClicked }) => {
  const classes = useStyles() //this is a hook to use the styles within styles.js
  const isDesktop = useMediaQuery('(min-width:600px)') //this will be false if the viewport is larger than 600px
  

  return (
    <div className={classes.mapContainer}>

      {/* GoogleMapReact is displaying the map through the api from google */}

      <GoogleMapReact 
      bootstrapURLKeys={ {key : process.env.REACT_APP_GOOGLE_MAPS_API_KEY}} 
      defaultCenter={coordinates} 
      center={coordinates} 
      defaultZoom={14} 
      margin={[50, 50, 50, 50]} 
      options={{ disableDefaultUI: true, zoomControl: true, styles: mapStyles }} 
      onChange={(e) => {
        setCoordinates({ lat: e.center.lat, lng: e.center.lng })
        setBounds({ ne: e.marginBounds.ne, sw: e.marginBounds.sw })
        console.log(e);
      }} 
      onChildClick={(child) => setChildClicked(child)} //this onClick event listener triggers function to click on the places on the map
      >

        {places?.map((place, i) => (
          <div className={classes.markerContainer} lat={Number(place.latitude)} lng={Number(place.longitude)} key={i}>
              {
                !isDesktop ? (
                  <LocationOnOutlinedIcon color='primary' fontSize='large'/>
                ) : (
                  // elevation is a property for depth and shadow
                  <Paper elevation={3} className={classes.paper}> 
                      <Typography className={classes.typography} variant='subtitle2' gutterBottom>
                          {place.name}
                      </Typography>
                      <img className={classes.pointer} src={place.photo ? place.photo.images.large.url : 'https://www.foodserviceandhospitality.com/wp-content/uploads/2016/09/Restaurant-Placeholder-001.jpg'} alt={place.name} />
                      <Rating size='small' value={Number(place.rating)} readOnly/>
                  </Paper>
                )
              }
          </div>
        ))}

      </GoogleMapReact>
    </div>
  )
}

export default Map
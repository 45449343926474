import React, { useState, useEffect } from 'react'
import './App.css';
import Header from './components/Header/Header'
import List from './components/List/List'
import Map from './components/Map/Map'
import { CssBaseline, Grid } from '@material-ui/core';
import { getPlacesData } from './api'

function App() {

  const [places, setPlaces] = useState([])
  const [filteredPlaces, setFilteredPlaces] = useState([])
  const [coordinates, setCoordinates] = useState({})
  const [bounds, setBounds] = useState({})
  const [childClicked, setChildClicked] = useState(null) //we want this state to be at the parent of the map and list components which will be used for when a child and img on map is clicked
  const [isLoading, setIsLoading] = useState(false)
  const [type, setType] = useState('restaurants')
  const [rating, setRating] = useState('')

  useEffect(() => { //this useEffect gets current location of user using built in browser location
    navigator.geolocation.getCurrentPosition(({ coords: {latitude, longitude}}) => {
        setCoordinates( {lat: latitude, lng: longitude})
    })
  })

  useEffect(() => {
    //using filter method to filter places to output rating which are greating then the current selected rating that is selected
    const filtered = places.filter((place) => Number(place.rating) > rating)

    setFilteredPlaces(filtered)
  }, [rating]) //when rating is changed or selected than component will reload
  
  console.log(places);
  console.log(filteredPlaces)
  

  useEffect(() => { //this useEffect gets data from api and updates data based on coordinates and bounds when they change
    if (bounds.sw && bounds.ne) { //want to grab data and set it into state only if there exists bounds

    setIsLoading(true)

    //also type is being sent to index where the URL is dynamic to search by type
    getPlacesData(type, bounds.sw, bounds.ne) //we are getting bound values from Map.js onchange and passing it to index.js 
      .then((data) => {
        console.log(data); 
        setPlaces(data?.filter((place) => place.name && place.num_reviews > 0)) //this is grabbing placedetails only if they have a name and reviews. Eliminating places on map without a name
        setFilteredPlaces([])
        setRating('')
        setIsLoading(false)
      })

    }
      
  }, [type, bounds])
 
  return (
    <>
      <CssBaseline/>
      <Header setCoordinates={setCoordinates}/>
      <Grid container spacing={3} style={{width: '100%'}}>
        <Grid item xs={12} md={4}>
          <List 
          // for places prop, we are checking if we have filteredPlaces and then sending those places and if not then sending normal places
          places={filteredPlaces.length ? filteredPlaces : places} 
          childClicked={childClicked} 
          isLoading={isLoading}
          type={type}
          setType={setType}
          rating={rating}
          setRating={setRating}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <Map 
          setCoordinates={setCoordinates} 
          setBounds={setBounds} 
          coordinates={coordinates} 
          places={filteredPlaces.length ? filteredPlaces : places} 
          setChildClicked={setChildClicked}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default App;
